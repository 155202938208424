.contacts-outer-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contacts-inner-div {
    width: 75%;
}

.contacts-inner-div a {
    color: inherit;
    text-decoration: none;

}

.contact-div {
    border-radius: 10px;
    height: 20vh;
    text-align: center;
}


#contact-image {
    height: 80%;
    width: auto;
}

#icon {
    font-size: 15vh;
}


.contact-div p {
    text-align: center;

    font-size: 2vh;
    font-family: "Libre Franklin", sans-serif;
    font-weight: 575;
    font-style: normal;

    padding: 0;
    margin: 0;
}