.languages-outer-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.languages-inner-div {
    width: 75%;
}

.language-div {
    border: 1px solid rgba(117, 113, 113, 0.73);
    border-radius: 10px;
    height: 8vh;
    display: flex;
    justify-content: center;
}


#language-image {
    height: 70%;
    width: auto;
}

.language-inner-div {
    width: 45%
}

.language-image-div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.language-name-div {
    align-items: center;
    display: flex;
}

.language-div p {
    text-align: left;

    font-size: 1.5vh;
    font-family: "Libre Franklin", sans-serif;
    font-weight: 575;
    font-style: normal;

    padding: 0;
    margin: 0;
}