.chart-outer-div {
    width: 100%;
    display: flex;
    justify-content: center;
}

.chart-inner-div {
    width: 75%;
}

#chart-img {
    width: 100%;
}




