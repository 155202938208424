.header-div {
    height: 75px;
    min-height: 75px;
    display: flex;
    color: black;
    margin-top: 1vh;
}

.header-div a {
    color: inherit;
    text-decoration: none;
}


.header-div button {
    cursor: pointer;
    font-size: 1.5em;
}

.tilde {
    vertical-align: middle;
    font-size: inherit;
}

ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.header-list {
    height: 100%;
    width: 100%;
}

.header-div .right {
    float: right;
}

.header-div li {
    float: left;
    height: 100%;
    padding-left: 1vw;
}

.header-div li button {
    height: 100%;
    display: table;
    text-align: center;
    text-decoration: none;
}

.header-div li {
    height: 100%;
    text-align: center;
    vertical-align: middle;
    display: table-cell;

    text-decoration: none;

    font-family: "Libre Franklin", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.header-div li p {
    font-size: 2vh;
    padding: 0;
    margin: 0;
}

.header-div li h1 {
    font-size: 4vh;
    font-weight: 400;
    padding: 0;
    margin: 0;
}

.header-div li p:hover:not(.active), .header-div li h1:hover:not(.active) {
    border-radius: 5px;
}

.header-div button {
    height: 100%;
    width: 100%;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}