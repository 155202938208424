.App {
    height: 100%;
    min-height: 100%;

    font-family: "Libre Franklin", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.content {
    width: 55vw;
    min-width: 55vw;
    margin: 0 auto;
}

.section-title {
    padding: 70px 0 70px 40px;
    font-weight: 400;
    font-size: 3vh;
    margin: 0;
}