.projects-outer {
    background: rgba(229, 220, 195, 0.28);
    width: 100%;
}

.projects-footer {
    height: 1vh;
}

.projects-content-outer-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.projects-content-inner-div {
    width: 85%;
}

#project-image {
    width: 100%;
    height: 100%;
    text-align: center;
    border-radius: 10px 10px 0 0;
}

.highlight-proj {
    height: 40vh !important;
}

.project-div {
    height: 20vh;
    width: 100%;

    display: flex;
    justify-content: center;
}

.project-image-div {
    height: 70%;
    width: 100%
}

.text-div {
    height: 30%;
    width: 100%;
    background: rgba(119, 119, 119, 0.56);
    border-radius: 0 0 10px 10px;

    display: flex;
    justify-content: center;
}

.text-inner-div {
    width: 80%;
}

.project-div p {
    text-align: left;
    font-family: "Libre Franklin", sans-serif;
    font-style: normal;

    padding: 0;
    margin: 0;
}

.project-inner-div {
    padding: 5px;

    width: 100%;
}

#project-highlight-grid {
    margin-top: 0.5vh;
}

#project-description {
    font-size: 1em;
    font-weight: 400
}

#project-title {
    font-size: 1.1em;
    font-weight: 650;
    padding-top: 1vh;
    margin-bottom: 0px;
}

.highlight-proj #project-title {
    margin-top: 1vh;
    font-size: 1.5em;
}

.highlight-proj #project-description {
    font-size: 1.1em;
}

.grid-outer {
    height: 100%;
    width: 100%;
    min-height: 100%;
    min-width: 100%;
}

.grid-inner {
    padding: 0;
}

.projects-content-inner-div .item {
    background: inherit;
}

.project-div:hover {
    -ms-transform: scale(1.07);
    transform: scale(1.07);
}