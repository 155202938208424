.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    background-color: #AAA492;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    transition: opacity 0.3s, visibility 0.3s;
    visibility: hidden;
    opacity: 0;
    z-index: 1000;
}

.scroll-to-top.show {
    visibility: visible;
    opacity: 1;
}
